import { PaymentResponseDto } from "./payments";

export enum TransactionFilterTypes {
  createdAtAfter = "createdAtAfter",
  updatedAtAfter = "updatedAtAfter",
  id = "id",
  type = "type",
  purpose = "purpose",
  note = "note",
  phoneNumber = "phoneNumber",
  userInitiated = "userInitiated",
}

export enum TransactionFilterNames {
  createdAtAfter = "Created at after",
  updatedAtAfter = "Updated at after",
  id = "ID",
  type = "Type",
  purpose = "Purpose",
  note = "Note",
  phoneNumber = "Phone number",
  userInitiated = "User initiated",
}

export enum TransactionTypeLabel {
  MANUAL = "Manual",
  ORDER_CHARGE = "Order charge",
  ORDER_REFUND = "Order refund",
  REFUND = "Refund",
  TOPUP = "Top up",
  PAYMENT_REFUND = "Payment refund",
  VOUCHER = "Voucher",
  BALANCE_EXTENSION = "Balance Extension",
}

export enum TransactionType {
  Manual = "MANUAL",
  OrderCharge = "ORDER_CHARGE",
  OrderRefund = "ORDER_REFUND",
  Refund = "REFUND",
  TopUp = "TOPUP",
  PaymentRefund = "PAYMENT_REFUND",
  Voucher = "VOUCHER",
  BalanceExtension = "BALANCE_EXTENSION",
}

export type TransactionResponseDto = {
  id: string;
  customerBalance: number;
  firstName: string | null;
  lastName: string | null;
  customerId: string;
  initiatorId: string | null;
  note: string | null;
  fmcCentsAmount: number;
  type: TransactionType | null;
  payment: PaymentResponseDto;
  orderId: string | null;
  refundDate: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  fullName?: string | null;
};

export type GetTransactionsResponseDto = {
  items: TransactionResponseDto[];
  totalCount: number;
};

export type CreateCustomerTransactionRequestDto = {
  customerId: string;
  fmcCentsAmount: number;
  note: string;
  purpose: string;
};
export type CreateBulkCustomerTransactionRequestDto = {
  transactions: CreateCustomerTransactionRequestDto[];
};

export type ExtendCustomerBalanceExpirationRequestDto = {
  customerId: string;
  days: number;
  note: string;
};

export type RefundTransactionRequestDto = {
  id: string;
  customerId: string;
  note: string;
  fmcCentsAmount: string;
};
