// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  NotificationsSettingsResponseDto,
  SetNotificationsSettingsRequestDto,
} from "types/notificationsSettings";
// Utils
import { coreQuery } from "utils/baseQuery";

import { endpoints } from "consts";

export const notificationsSettingsAPI = createApi({
  reducerPath: "notificationsSettingsAPI",
  baseQuery: coreQuery(),
  tagTypes: ["notifications"],

  endpoints: build => ({
    updateNotificationsSettings: build.mutation<
      NotificationsSettingsResponseDto,
      SetNotificationsSettingsRequestDto
    >({
      query: data => ({
        url: endpoints.notificationsSettings,
        method: "PUT",
        data,
      }),
    }),

    getNotificationsSettings: build.query<
      NotificationsSettingsResponseDto,
      void
    >({
      query: () => ({
        url: endpoints.notificationsSettings,
        method: "GET",
      }),
      providesTags: ["notifications"],
    }),
  }),
});

export const {
  useLazyGetNotificationsSettingsQuery,
  useUpdateNotificationsSettingsMutation,
} = notificationsSettingsAPI;
