import { FeeRange } from "types/coreSettings";
import { RulesForm } from ".";

export const validateRage = (
  { maxOrderValue, minOrderValue }: RulesForm,
  rules: FeeRange[],
) => {
  const isFromIncludes = rules.find(row => {
    return (
      minOrderValue >= row?.minOrderValue && minOrderValue <= row?.maxOrderValue
    );
  });

  const isToIncludes = rules.find(row => {
    return (
      maxOrderValue >= row?.minOrderValue && maxOrderValue <= row?.maxOrderValue
    );
  });

  const isHighestRange = rules.find(row => {
    return (
      minOrderValue <= row?.minOrderValue && maxOrderValue >= row?.maxOrderValue
    );
  });

  if (isFromIncludes || isToIncludes || isHighestRange) {
    return false;
  }

  return true;
};
