import { Permission } from "consts";
import {
  FormFieldsData,
  GetManyRolesResponseDto,
  RolesPermissionsState,
} from "types/roles";

//To fix form fields
export const dotReplace = (
  str: string | Permission,
  replace: boolean,
): string => {
  if (replace) {
    return str.replaceAll(".", "$");
  } else {
    return str.replaceAll("$", ".");
  }
};

export const createSegmentedFormFields = (
  permissions: Permission[] | undefined,
): FormFieldsData => {
  if (!permissions?.length) {
    return {
      users: [],
      roles: [],
      settings: [],
      coupons: [],
      delivery: [],
      transactions: [],
      orders: [],
      packages: [],
      locationProviders: [],
    };
  }

  const fields = permissions
    .sort((a, b) => a.localeCompare(b))
    .reduce(
      (acc, el) => {
        switch (el) {
          case Permission.UsersGet:
          case Permission.UsersCreate:
          case Permission.UsersUpdate:
          case Permission.UsersDelete:
          case Permission.UserAgentOnlyCreate:
          case Permission.EmployeesGet:
          case Permission.EmployeesCreate:
          case Permission.CustomerBase:
          case Permission.CustomersGet:
          case Permission.CustomersVIPStatus:
          case Permission.CustomersActivate:
          case Permission.PermissionsGet:
          case Permission.BalanceGet:
          case Permission.InvitationsGet:
          case Permission.InvitationsCreate:
          case Permission.StatisticsGet:
            acc.users.push(dotReplace(el, true));
            break;

          case Permission.OrdersGet:
          case Permission.OrdersCreate:
          case Permission.SettingsScheduleOrderGet:
          case Permission.SettingsScheduleOrderUpdate:
            acc.orders.push(dotReplace(el, true));
            break;

          case Permission.RolesGet:
          case Permission.RolesCreate:
          case Permission.RolesUpdate:
          case Permission.RolesDelete:
            acc.roles.push(dotReplace(el, true));
            break;

          case Permission.SettingsGet:
          case Permission.SettingsUpdate:
          case Permission.SettingsCouponsGet:
          case Permission.SettingsCouponsUpdate:
          case Permission.SettingsMenuGridGet:
          case Permission.SettingsMenuGridUpdate:
          case Permission.SettingsPaymentsGet:
          case Permission.SettingsPaymentsUpdate:
          case Permission.SettingsPurposeGet:
          case Permission.SettingsPurposeCreate:
          case Permission.SettingsPurposeUpdate:
          case Permission.SettingsPurposeDelete:
          case Permission.SettingsReferralsGet:
          case Permission.SettingsReferralsUpdate:
          case Permission.SettingsTimeSlotsGet:
          case Permission.SettingsTimeSlotsCreate:
          case Permission.SettingsTimeSlotsUpdate:
          case Permission.SettingsTimeSlotsDelete:
          case Permission.ExchangeRateUpdate:
          case Permission.RefundAddAgentLimitUpdate:
          case Permission.RefundAddManagerLimitUpdate:
          case Permission.BalanceExpirationAgentLimitUpdate:
          case Permission.BalanceExpirationManagerLimitUpdate:
          case Permission.WaitingListUpdate:
          case Permission.StockManagementGet:
            acc.settings.push(dotReplace(el, true));
            break;

          case Permission.CouponsGet:
          case Permission.CouponsCreate:
          case Permission.CouponsUpdate:
          case Permission.CouponsDelete:
            acc.coupons.push(dotReplace(el, true));
            break;

          case Permission.PackagesGet:
          case Permission.PackagesCreate:
          case Permission.PackagesUpdate:
            acc.packages.push(dotReplace(el, true));
            break;

          case Permission.LocationsGet:
          case Permission.LocationsMenuGet:
          case Permission.LocationsUpdate:
          case Permission.DeliveriesAllOptions:
            acc.delivery.push(dotReplace(el, true));
            break;

          case Permission.TransactionsGet:
          case Permission.TransactionsCreate:
          case Permission.BalanceExpirationUpdate:
            acc.transactions.push(dotReplace(el, true));
            break;

          case Permission.LocationProvidersGet:
            acc.locationProviders.push(dotReplace(el, true));
            break;

          default:
            break;
        }

        return acc;
      },
      {
        users: [],
        roles: [],
        settings: [],
        coupons: [],
        delivery: [],
        transactions: [],
        orders: [],
        packages: [],
        locationProviders: [],
      },
    );

  return fields;
};

export const handleInitialEditState = (
  id: string,
  permissionsData: Permission[],
  rolesData: GetManyRolesResponseDto,
  name?: string,
): RolesPermissionsState => {
  const role = rolesData?.items?.find(role => role.id === id);

  const state: RolesPermissionsState = permissionsData.reduce(
    (acc, permission) => {
      acc[dotReplace(permission, true)] = !!role?.permissions?.find(
        p => p.name === permission,
      );

      return acc;
    },
    {
      name: name || role.name,
    } as RolesPermissionsState,
  );

  return state;
};

export const handleInitialCreateState = (
  permissionsData: Permission[],
  name?: string,
): RolesPermissionsState => {
  const state: RolesPermissionsState = permissionsData.reduce(
    (acc: RolesPermissionsState, permission: Permission) => {
      acc[dotReplace(permission, true)] = false;

      return acc;
    },
    { name: name || "" } as RolesPermissionsState,
  );

  return state;
};

export const selectAllFields = (
  obj: RolesPermissionsState,
): RolesPermissionsState => {
  const { name, ...data } = obj;

  const result = {};

  for (const key in data) {
    result[key] = true;
  }

  return {
    name,

    ...result,
  } as RolesPermissionsState;
};

export const clearAllFields = (
  obj: RolesPermissionsState,
): RolesPermissionsState => {
  const { name, ...data } = obj;

  const result = {};

  for (const key in data) {
    result[key] = false;
  }

  return {
    name,
    ...result,
  } as RolesPermissionsState;
};

export const isAllChecked = (obj: RolesPermissionsState): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name, ...data } = obj;
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && !obj[key]) {
      return false;
    }
  }
  return true;
};

export const isAllCleared = (obj: RolesPermissionsState): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name, ...data } = obj;
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key]) {
      return false;
    }
  }
  return true;
};

export const isNameAlreadyExist = ({
  id,
  name,
  rolesData,
}: {
  id: string;
  name: string;
  rolesData: GetManyRolesResponseDto;
}): boolean => {
  const rolesWithThisName = rolesData.items.filter(
    r => r.name.toLowerCase() === name.toLowerCase(),
  );

  if (!rolesWithThisName?.length) {
    return false;
  }

  if (id) {
    return !!rolesWithThisName.filter(r => r.id !== id)?.length;
  }

  return !!rolesWithThisName?.length;
};

export const convertToPayload = ({
  data,
  hiddenPermissions = [],
}: {
  data: RolesPermissionsState;
  hiddenPermissions?: {
    name: string;
  }[];
}) => {
  const { name, ...booleanFields } = data;

  const checkedPermissions = Object.keys(booleanFields)
    .filter(key => booleanFields[key])
    .map(str => ({ name: dotReplace(str, false) }));

  return {
    name,
    permissions: checkedPermissions.concat(hiddenPermissions),
  };
};

export const removeHiddenPermissions = (permissionsData: Permission[]) => {
  const hiddenPermissions = [Permission.SuperAdminManagement];

  return permissionsData?.filter(p => !hiddenPermissions.includes(p));
};

export const getHiddenPermissions = (
  id: string,
  rolesData: GetManyRolesResponseDto,
): { name: Permission }[] => {
  const role = rolesData.items.find(role => role.id === id);

  const hiddenPermissions = [Permission.SuperAdminManagement];

  const list = [];

  role.permissions.forEach(permission => {
    if (hiddenPermissions.includes(permission.name as Permission)) {
      list.push({ name: permission.name });
    }
  });

  return list;
};
