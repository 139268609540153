// Lib
import { FC, useEffect, useState } from "react";
// Api
import {
  useLazyGetExchangeRateQuery,
  useUpdateExchangeRateMutation,
} from "rtkQuery/query/exchangeRateAPI";
import {
  useLazyGetBalanceExpirationQuery,
  useUpdateBalanceExpirationMutation,
} from "rtkQuery/query/balanceExpirationAPI";
import {
  useLazyGetSettingsQuery,
  useUpdateSettingsMutation,
} from "rtkQuery/query/settingsAPI";
import {
  useLazyGetAuthSettingsQuery,
  useUpdateAuthSettingsMutation,
} from "rtkQuery/query/authSettingsAPI";
import { useUpdateCoreSettingsMutation } from "rtkQuery/query/coreSettingsAPI";
// Hooks
import { useNotification, usePermissions } from "hooks";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import SettingsItem from "../SettingsItem";
import { Input } from "../Inputs";
// Styled
import { ContentBox } from "styled/Box";
import { Typography } from "styled/Typography";

import {
  ConfirmModalType,
  CoreSettingsKey,
  GeneralField,
  initialLoadingState,
  initialState,
  LoadingStateValues,
  notificationMessage,
  StateValues,
} from "./config";
import { Switch } from "../Inputs/Switch";
import { ConfirmDialog } from "components/Modals";
import { PersonIcon } from "icons";
import { getConfirmModalTitle } from "../../helpers";

export const GeneralSettings: FC = () => {
  const { openNotification } = useNotification();

  const {
    canSettingsGet,
    canExchangeRateUpdate,
    canStockManagementSettingsGet,
    canSettingsUpdate,
    canBalanceExpirationUpdate,
  } = usePermissions();

  const [fetchBalanceExpiration] = useLazyGetBalanceExpirationQuery();
  const [fetchExchangeRate] = useLazyGetExchangeRateQuery();
  const [fetchSettings] = useLazyGetSettingsQuery();
  const [fetchAuthSettings] = useLazyGetAuthSettingsQuery();
  const [updateAuthSettings] = useUpdateAuthSettingsMutation();
  const [updateExchangeRate] = useUpdateExchangeRateMutation();
  const [updateBalanceExpiration] = useUpdateBalanceExpirationMutation();
  const [updateSettings] = useUpdateSettingsMutation();
  const [updateCoreSettings] = useUpdateCoreSettingsMutation();

  const [confirmModal, setConfirmModal] = useState<ConfirmModalType | false>(
    false,
  );

  const [settings, setSettings] = useState<StateValues>(initialState);

  const [isLoading, setIsLoading] =
    useState<LoadingStateValues>(initialLoadingState);

  const getBalanceExpiration = async () => {
    try {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.BalanceExpirationDays]: true,
      }));

      const { days } = await fetchBalanceExpiration().unwrap();

      setSettings(prev => ({ ...prev, balanceExpirationDays: days }));
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.BalanceExpirationDays]: false,
      }));
    }
  };

  const getExchangeRate = async () => {
    try {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.ExchangeRateValue]: true,
      }));
      const { value } = await fetchExchangeRate().unwrap();

      setSettings(prev => ({ ...prev, exchangeRateValue: value }));
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.ExchangeRateValue]: false,
      }));
    }
  };

  const getMinimunSettings = async () => {
    try {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.MinimumTopUpValue]: true,
        [GeneralField.MinimumOrderValue]: true,
      }));

      const { minimumTopUpValue, minimumOrderValue } =
        await fetchSettings().unwrap();

      setSettings(prev => ({
        ...prev,
        minimumTopUpValue,
        minimumOrderValue,
      }));
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.MinimumTopUpValue]: false,
        [GeneralField.MinimumOrderValue]: false,
      }));
    }
  };

  const getAuthSettings = async () => {
    try {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.InstantOrderCancellationTime]: true,
        [GeneralField.DefaultDeliveryTime]: true,
        [GeneralField.DefaultPreparationTime]: true,
        [GeneralField.IsStockActive]: true,
      }));

      const {
        instantOrderCancellationTime,
        defaultDeliveryTime,
        defaultPreparationTime,
        isStockActive,
      } = await fetchAuthSettings().unwrap();

      setSettings(prev => ({
        ...prev,
        instantOrderCancellationTime,
        defaultDeliveryTime,
        defaultPreparationTime,
        isStockActive,
      }));
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.InstantOrderCancellationTime]: false,
        [GeneralField.DefaultDeliveryTime]: false,
        [GeneralField.DefaultPreparationTime]: false,
        [GeneralField.IsStockActive]: false,
      }));
    }
  };

  useEffect(() => {
    if (!canSettingsGet) {
      return;
    }

    getBalanceExpiration();
    getExchangeRate();
    getMinimunSettings();
    getAuthSettings();
  }, []);

  const handleUpdateSettings = async (
    key: GeneralField,
    value: number | boolean,
  ) => {
    const isValueNumber = typeof value === "number";
    try {
      setIsLoading(prev => ({ ...prev, [key]: true }));

      const payload = {
        [GeneralField.MinimumTopUpValue]:
          settings[GeneralField.MinimumTopUpValue],
        [key]: value,
      };

      if (isValueNumber) {
        await updateSettings(payload).unwrap();

        setSettings(prev => ({ ...prev, [key]: value }));
      } else {
        await updateSettings(payload).unwrap();
      }

      openNotification({ message: notificationMessage[key] });
    } catch (error) {
      if (!isValueNumber) {
        setSettings(prev => ({ ...prev, [key]: !value }));
      }

      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({ ...prev, [key]: false }));
    }
  };

  const handleUpdateExchangeRateValue = async (
    key: GeneralField.ExchangeRateValue,
    value: number,
  ) => {
    try {
      setIsLoading(prev => ({ ...prev, [key]: true }));

      await updateExchangeRate({ value }).unwrap();

      setSettings(prev => ({ ...prev, [key]: value }));

      openNotification({ message: notificationMessage[key] });
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({ ...prev, [key]: false }));
    }
  };

  const handleUpdateBalanceExpiration = async (
    key: GeneralField.BalanceExpirationDays,
    days: number,
  ) => {
    try {
      setIsLoading(prev => ({ ...prev, [key]: true }));

      await updateBalanceExpiration({ days }).unwrap();

      setSettings(prev => ({ ...prev, [key]: days }));

      openNotification({ message: notificationMessage[key] });
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({ ...prev, [key]: false }));
    }
  };

  const handleUpdateAuthSettings = async (
    key: GeneralField,
    value: boolean,
  ) => {
    try {
      setIsLoading(prev => ({ ...prev, [key]: true }));

      const payload = {
        [key]: value,
      };

      setSettings(prev => ({ ...prev, [key]: value }));

      await updateAuthSettings(payload).unwrap();

      openNotification({ message: notificationMessage[key] });
    } catch (error) {
      setSettings(prev => ({ ...prev, [key]: !value }));

      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({ ...prev, [key]: false }));

      if (confirmModal) {
        setConfirmModal(false);
      }
    }
  };

  const handleUpdateCoreSettings = async (
    key: CoreSettingsKey,
    value: number,
  ) => {
    try {
      setIsLoading(prev => ({ ...prev, [key]: true }));

      await updateCoreSettings({ [key]: value }).unwrap();

      setSettings(prev => ({ ...prev, [key]: value }));

      openNotification({ message: notificationMessage[key] });
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({ ...prev, [key]: false }));
    }
  };

  const handleUpdateBooleanUpdate = (key: GeneralField, value: boolean) => {
    setSettings(prev => ({ ...prev, [key]: value as boolean }));
    setConfirmModal({ key, value });
  };

  const onConfirm = () => {
    if (!confirmModal) return;

    handleUpdateAuthSettings(confirmModal.key, confirmModal.value as boolean);
  };

  const onCancel = () => {
    if (!confirmModal) return;
    setSettings(prev => ({ ...prev, [confirmModal.key]: !confirmModal.value }));
    setConfirmModal(false);
  };

  return (
    <div>
      <ContentBox $column $gap={12}>
        <Typography.H2>General</Typography.H2>

        <div>
          <SettingsItem
            title="Exchange rate"
            description="Set FM coin exchange rate"
          >
            <Input
              step={0.1}
              precision={1}
              isLoading={isLoading[GeneralField.ExchangeRateValue]}
              canUpdate={canSettingsUpdate && canExchangeRateUpdate}
              data={settings[GeneralField.ExchangeRateValue]}
              onSubmit={value =>
                handleUpdateExchangeRateValue(
                  GeneralField.ExchangeRateValue,
                  value,
                )
              }
            />
          </SettingsItem>

          <SettingsItem
            title="Virtual currency expiration time"
            description="Set the expiration date when the user topup the balance"
          >
            <Input
              isLoading={isLoading[GeneralField.BalanceExpirationDays]}
              canUpdate={canSettingsUpdate && canBalanceExpirationUpdate}
              data={settings[GeneralField.BalanceExpirationDays]}
              onSubmit={value =>
                handleUpdateBalanceExpiration(
                  GeneralField.BalanceExpirationDays,
                  value,
                )
              }
            />
          </SettingsItem>

          <SettingsItem
            title="Minimum top up (KWD)"
            description="Set the minimum to top up the balance"
          >
            <Input
              suffix="KWD"
              fixedDigitsPlaceholder={3}
              step={0.001}
              isLoading={isLoading[GeneralField.MinimumTopUpValue]}
              canUpdate={canSettingsUpdate}
              data={settings[GeneralField.MinimumTopUpValue]}
              onSubmit={value =>
                handleUpdateSettings(GeneralField.MinimumTopUpValue, value)
              }
            />
          </SettingsItem>

          <SettingsItem
            title="Minimum order (FM Coins)"
            description="Set the minimum coins required to proceed with the order for users with active coin package"
          >
            <Input
              suffix="FM Coins"
              fixedDigitsPlaceholder={3}
              step={0.001}
              isLoading={isLoading[GeneralField.MinimumOrderValue]}
              canUpdate={canSettingsUpdate}
              data={settings[GeneralField.MinimumOrderValue]}
              onSubmit={value =>
                handleUpdateSettings(GeneralField.MinimumOrderValue, value)
              }
            />
          </SettingsItem>

          <SettingsItem
            title="Minimum instant order rejection time (minutes)"
            description="Set a minimum order rejection time before starting order preparation for instant orders"
          >
            <Input
              suffix="minutes"
              step={1}
              min={1}
              isLoading={isLoading[GeneralField.InstantOrderCancellationTime]}
              canUpdate={canSettingsUpdate}
              data={settings[GeneralField.InstantOrderCancellationTime]}
              onSubmit={value =>
                handleUpdateCoreSettings(
                  GeneralField.InstantOrderCancellationTime,
                  value,
                )
              }
            />
          </SettingsItem>

          <SettingsItem
            title="Default order preparation time"
            description="The Order Preparation Time will be used to calculate and display the start time of the Estimated Arrival Time range on the mobile app"
          >
            <Input
              suffix="minutes"
              step={1}
              min={1}
              isLoading={isLoading[GeneralField.DefaultPreparationTime]}
              canUpdate={canSettingsUpdate}
              data={settings[GeneralField.DefaultPreparationTime]}
              onSubmit={value =>
                handleUpdateCoreSettings(
                  GeneralField.DefaultPreparationTime,
                  value,
                )
              }
            />
          </SettingsItem>

          <SettingsItem
            title="Default order delivery time"
            description="Set default order delivery time"
          >
            <Input
              suffix="minutes"
              step={1}
              min={1}
              isLoading={isLoading[GeneralField.DefaultDeliveryTime]}
              canUpdate={canSettingsUpdate}
              data={settings[GeneralField.DefaultDeliveryTime]}
              onSubmit={value =>
                handleUpdateCoreSettings(
                  GeneralField.DefaultDeliveryTime,
                  value,
                )
              }
            />
          </SettingsItem>

          {canStockManagementSettingsGet && (
            <SettingsItem
              title="Restrict orders based on stock availability"
              description="Enable this setting to prevent users from ordering menu items with insufficient stock."
            >
              <Switch
                isLoading={isLoading[GeneralField.IsStockActive]}
                canUpdate={canSettingsUpdate && canSettingsUpdate}
                data={settings[GeneralField.IsStockActive]}
                onChange={value =>
                  handleUpdateBooleanUpdate(GeneralField.IsStockActive, value)
                }
              />
            </SettingsItem>
          )}
        </div>
      </ContentBox>

      <ConfirmDialog
        isLoading={confirmModal && isLoading[confirmModal.key]}
        open={!!confirmModal}
        Icon={PersonIcon}
        message={
          confirmModal &&
          getConfirmModalTitle({
            key: confirmModal.key,
            value: confirmModal.value,
          })
        }
        description="Are you sure to continue this action?"
        onCancel={onCancel}
        firstCTAButton={{
          title: "Accept",
          status: "danger",
          disabled: false,
          loading: confirmModal && isLoading[confirmModal.key],
          onClick: onConfirm,
        }}
      />
    </div>
  );
};
