// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  CoreSettingsResponseDto,
  RewardsSettingsResponseDto,
  SetCoreSettingsRequestDto,
  SetDeliveryFeeSettingsRequestDto,
} from "types/coreSettings";
// Utils
import { coreQuery } from "utils/baseQuery";

import { endpoints } from "consts";
import {
  coreSettingsDeliveryFeePayloadMapper,
  coreSettingsMapper,
  coreSettingsPayloadMapper,
  rewardsSettingsMapper,
  rewardsSettingsPayloadMapper,
} from "utils/mappers";

export const coreSettingsAPI = createApi({
  reducerPath: "coreSettingsAPI",
  baseQuery: coreQuery(),
  tagTypes: ["rewards", "coreSettings", "deliveryFeeSettings"],

  endpoints: build => ({
    getCoreSettings: build.query<CoreSettingsResponseDto, void>({
      query: () => ({
        url: endpoints.coreSettings,
        method: "GET",
      }),
      providesTags: ["coreSettings", "deliveryFeeSettings"],
      transformResponse: (response: CoreSettingsResponseDto) =>
        coreSettingsMapper(response),
    }),
    updateCoreSettings: build.mutation<void, SetCoreSettingsRequestDto>({
      query: data => ({
        url: endpoints.coreSettings,
        method: "PUT",
        data: coreSettingsPayloadMapper(data),
      }),
    }),
    updateDeliveryFeeSettings: build.mutation<
      void,
      SetDeliveryFeeSettingsRequestDto
    >({
      query: data => ({
        url: endpoints.coreSettings,
        method: "PUT",
        data: coreSettingsDeliveryFeePayloadMapper(data),
      }),
      invalidatesTags: ["deliveryFeeSettings"],
    }),
    getRewardSettings: build.query<RewardsSettingsResponseDto, void>({
      query: () => ({
        url: endpoints.rewards,
        method: "GET",
      }),
      providesTags: ["rewards"],
      transformResponse: (response: RewardsSettingsResponseDto) =>
        rewardsSettingsMapper(response),
    }),
    updateRewardSettings: build.mutation<
      RewardsSettingsResponseDto,
      RewardsSettingsResponseDto
    >({
      query: data => ({
        url: endpoints.rewards,
        method: "PUT",
        data: rewardsSettingsPayloadMapper(data),
      }),
      invalidatesTags: ["rewards"],
    }),
  }),
});

export const {
  useGetCoreSettingsQuery,
  useLazyGetCoreSettingsQuery,
  useUpdateDeliveryFeeSettingsMutation,
  useUpdateCoreSettingsMutation,
  useLazyGetRewardSettingsQuery,
  useUpdateRewardSettingsMutation,
} = coreSettingsAPI;
