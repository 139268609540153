import dayjs from "dayjs";
import { EFilterDateValue } from "types/common";
import { FilterField, FilterStateItem } from "types/tableFilters";
import { dateCreate, dateTransformToRequestFormat } from "./dataHelpers";

const createCustomDate = (date: string, time: string): string => {
  const hour = dayjs(time).hour();
  const minute = dayjs(time).minute();
  return dayjs(date)
    .hour(hour)
    .minute(minute)
    .second(0)
    .millisecond(0)
    .utc()
    .toISOString();
};

export const getFiltersQuery = (filters: FilterStateItem[]) => {
  if (!filters?.length) return {};

  return filters.reduce((acc: Record<string, string>, f: FilterStateItem) => {
    if (f.field === FilterField.DATE) {
      if (
        f.name.toLowerCase().includes("after") &&
        typeof f.value === "string"
      ) {
        return {
          ...acc,
          [f.name]: f?.value
            ? dayjs(f.value).startOf("day")?.toISOString()
            : "",
        };
      }
    }

    if (f.field === FilterField.DATE_RANGE_ONLY) {
      const isDaysRageDate =
        !!f.subValue?.date?.start && !!f.subValue?.date?.end;

      if (!isDaysRageDate) return { ...acc };

      const isEmptyOneTimeRangeField =
        (!!f.subValue.time?.start && !f.subValue.time?.end) ||
        (!f.subValue.time?.start && !!f.subValue.time?.end);

      if (isEmptyOneTimeRangeField) {
        return { ...acc };
      } else if (!isEmptyOneTimeRangeField) {
        const startHour = f.subValue?.time?.start
          ? dateCreate(f.subValue?.time?.start)?.hour()
          : 0;
        const startMinute = f.subValue?.time?.start
          ? dateCreate(f.subValue?.time?.start)?.minute()
          : 0;
        const endHour = f.subValue?.time?.end
          ? dateCreate(f.subValue?.time?.end)?.hour()
          : 0;
        const endMinute = f.subValue?.time?.end
          ? dateCreate(f.subValue?.time?.end)?.minute()
          : 0;

        return {
          ...acc,
          [`${f.name}`]: [
            `${dateTransformToRequestFormat(
              dateCreate(f.subValue?.date?.start)
                .hour(startHour)
                .minute(startMinute),
            )}`,
            `${dateTransformToRequestFormat(
              dateCreate(f.subValue?.date?.end).hour(endHour).minute(endMinute),
            )}`,
          ],
        };
      }

      return {
        ...acc,
        [`${f.name}`]: [
          `${dateTransformToRequestFormat(
            dateCreate(f.subValue?.date?.start).startOf("day"),
          )}`,
          `${dateTransformToRequestFormat(
            dateCreate(f.subValue?.date?.end).endOf("day"),
          )}`,
        ],
      };
    }

    if (!f.value) return { ...acc };

    if (f.field === FilterField.DATE) {
      if (
        f.name.toLowerCase().includes("after") &&
        typeof f.value === "string"
      ) {
        return {
          ...acc,
          [f.name]: dayjs(f.value).startOf("day")?.toISOString(),
        };
      }
    }
    if (f.field === FilterField.MULTISELECT_SINGLE_DATE) {
      if (
        f.name.toLowerCase().includes("after") &&
        typeof f.value === "string"
      ) {
        switch (f.value) {
          case EFilterDateValue.CUSTOM:
            return !f?.subValue?.date?.start
              ? { ...acc }
              : {
                  ...acc,
                  [f.name]: dayjs(f?.subValue?.date?.start)
                    .startOf("day")
                    ?.toISOString(),
                };

          case EFilterDateValue.TODAY:
            return {
              ...acc,
              [f.name]: dayjs().startOf("day")?.toISOString(),
            };
          case EFilterDateValue.YESTERDAY:
            return {
              ...acc,
              [f.name]: dayjs()
                .startOf("day")
                .subtract(1, "day")
                ?.toISOString(),
            };
          case EFilterDateValue.IN_THE_LAST_7_DAYS:
            return {
              ...acc,
              [f.name]: dayjs()
                .startOf("day")
                .subtract(7, "day")
                ?.toISOString(),
            };
          case EFilterDateValue.IN_THE_LAST_30_DAYS:
            return {
              ...acc,
              [f.name]: dayjs()
                .startOf("day")
                .subtract(30, "day")
                ?.toISOString(),
            };
          case EFilterDateValue.IN_THE_LAST_90_DAYS:
            return {
              ...acc,
              [f.name]: dayjs()
                .startOf("day")
                .subtract(90, "day")
                ?.toISOString(),
            };
          case EFilterDateValue.IN_THE_12_MONTHS:
            return {
              ...acc,
              [f.name]: dayjs()
                .startOf("day")
                .subtract(12, "month")
                ?.toISOString(),
            };

          default:
            break;
        }

        return { ...acc, [f.name]: dayjs(f.value).endOf("day")?.toISOString() };
      }
    }

    if (f.field === FilterField.DATE_RANGE) {
      if (typeof f.value === "string") {
        switch (f.value) {
          case EFilterDateValue.CUSTOM:
            return !!f?.subValue?.date?.start &&
              !!f?.subValue?.date?.end &&
              !!f?.subValue?.time?.start &&
              !!f?.subValue?.time?.end
              ? {
                  ...acc,
                  [`${f.name}From`]: createCustomDate(
                    f?.subValue?.date?.start,
                    f?.subValue?.time?.start,
                  ),
                  [`${f.name}To`]: createCustomDate(
                    f?.subValue?.date?.end,
                    f?.subValue?.time?.end,
                  ),
                }
              : { ...acc };

          case EFilterDateValue.TODAY:
            return {
              ...acc,
              [`${f.name}From`]: dayjs().startOf("day")?.toISOString(),
              [`${f.name}To`]: dayjs().endOf("day")?.toISOString(),
            };
          case EFilterDateValue.YESTERDAY:
            return {
              ...acc,
              [`${f.name}From`]: dayjs()
                .subtract(1, "day")
                .startOf("day")
                ?.toISOString(),
              [`${f.name}To`]: dayjs()
                .subtract(1, "day")
                .endOf("day")
                ?.toISOString(),
            };
          case EFilterDateValue.IN_THE_LAST_7_DAYS:
            return {
              ...acc,
              [`${f.name}From`]: dayjs()
                .startOf("day")
                .subtract(8, "day")
                ?.toISOString(),
              [`${f.name}To`]: dayjs()
                .endOf("day")
                .subtract(1, "day")
                ?.toISOString(),
            };
          case EFilterDateValue.IN_THE_LAST_30_DAYS:
            return {
              ...acc,
              [`${f.name}From`]: dayjs()
                .startOf("day")
                .subtract(31, "day")
                ?.toISOString(),
              [`${f.name}To`]: dayjs()
                .endOf("day")
                .subtract(1, "day")
                ?.toISOString(),
            };
          case EFilterDateValue.IN_THE_LAST_90_DAYS:
            return {
              ...acc,
              [`${f.name}From`]: dayjs()
                .startOf("day")
                .subtract(91, "day")
                ?.toISOString(),
              [`${f.name}To`]: dayjs()
                .endOf("day")
                .subtract(1, "day")
                ?.toISOString(),
            };
          case EFilterDateValue.IN_THE_12_MONTHS:
            return {
              ...acc,
              [`${f.name}From`]: dayjs()
                .startOf("day")
                .subtract(12, "month")
                ?.toISOString(),
              [`${f.name}To`]: dayjs()
                .endOf("day")
                .subtract(1, "day")
                ?.toISOString(),
            };

          default:
            break;
        }

        return { ...acc, [f.name]: dayjs(f.value).endOf("day")?.toISOString() };
      }
    }

    return {
      ...acc,
      [f.name]: f.value,
    };
  }, {});
};
