import { yupResolver } from "@hookform/resolvers/yup";
import { number, object } from "yup";

const schema = object({
  minOrderValue: number()
    .nullable()
    .required("Min Order Value is required")
    .test("from-to", "value must be less than maximum", function (value) {
      const { maxOrderValue } = this.parent;
      return (
        value === null ||
        maxOrderValue === null ||
        value * 1000 < maxOrderValue * 1000
      );
    }),
  maxOrderValue: number().nullable().required("Max Order Value is required"),
  deliveryFee: number().nullable().required("Delivery Fee is required"),
}).required();

export const resolver = yupResolver(schema);
