// Lib
import { FC, memo, useCallback } from "react";
import { Dayjs } from "dayjs";
// Api
// Hooks
// Actions
// Selectors
// Types
import {
  FilterCustomDate,
  FilterField,
  FilterOption,
  FilterStateItem,
} from "types/tableFilters";
// Theme
import { theme } from "theme";
// Constants
// Helpers
import { dateCreate } from "helpers/dataHelpers";
import { getFilterDropdownOptions, getFormatedValue } from "../helpers";
// Utils
// Icons
import { ArrowDropDownBlackIcon } from "icons";
// Layouts
// Components
import { RangePicker } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { CloseButton, CloseIcon, FilterSelect } from "../styled";

interface DateRangeFilterProps {
  name: string;
  field: FilterField;
  filters: FilterOption[];
  isOpen: object | Record<string, boolean>;
  selected: FilterStateItem[] | [];
  value: string;
  subValue?: FilterCustomDate;
  setIsOpen: (
    value: React.SetStateAction<object | Record<string, boolean>>,
  ) => void;
  setFilterValue: (
    value: string,
    name: string,
    subValue?: FilterCustomDate,
  ) => void;
}

export const DateRangeFilter: FC<DateRangeFilterProps> = ({
  name,
  field,
  filters,
  isOpen,
  selected,
  value,
  subValue,
  setIsOpen,
  setFilterValue,
}) => {
  const setDateFilterValue = useCallback(
    (value: string, name: string, subValue?: FilterCustomDate) => {
      setFilterValue(value, name, subValue);
    },
    [selected],
  );

  return (
    <>
      <FilterSelect
        $inputType={field}
        suffixIcon={
          <FlexContainer $align="center">
            <ArrowDropDownBlackIcon />
            <div
              style={{
                width: 20,
                height: 30,
                borderLeft: `1px solid ${theme.color.borderMedium}`,
              }}
            />
          </FlexContainer>
        }
        removeIcon={<CloseIcon />}
        open={isOpen[name]}
        popupMatchSelectWidth={300}
        onMouseDown={() => setIsOpen({ [name]: true })}
        onBlur={() => {
          return;
        }}
        onChange={v => setFilterValue(v, name)}
        value={getFormatedValue(filters, name, value, subValue)}
        options={getFilterDropdownOptions(filters, selected, name)}
        dropdownRender={() => {
          return (
            <>
              <TableDateRangePicker
                name={name}
                selected={selected}
                setIsOpen={setIsOpen}
                subValue={subValue}
                setDateFilterValue={setDateFilterValue}
              />
            </>
          );
        }}
      />
    </>
  );
};

interface TableDateRangePickerProps {
  name: string;
  selected: FilterStateItem[];
  subValue?: FilterCustomDate;
  setIsOpen: (value: Record<string, boolean>) => void;
  setDateFilterValue: (
    value: string,
    name: string,
    subValue?: FilterCustomDate,
  ) => void;
}

const TableDateRangePicker: FC<TableDateRangePickerProps> = memo(
  ({ selected, name, subValue, setIsOpen, setDateFilterValue }) => {
    const selectedFilter = selected.find(
      (f: FilterStateItem) => f?.name === name,
    );

    const dateChangeHandler = (range: [Dayjs, Dayjs], isDate?: boolean) => {
      if (!range?.length) {
        setDateFilterValue(selectedFilter?.value as string, name, {
          [isDate ? "date" : "time"]: { start: null, end: null },
        });

        return;
      }

      if (!range[0] || !range[1]) {
        return;
      }

      const start = dateCreate(range[0]?.second(0))?.toISOString() || null;
      const end = dateCreate(range[1]?.second(0))?.toISOString() || null;

      setDateFilterValue(selectedFilter?.value as string, name, {
        [isDate ? "date" : "time"]: { start, end },
      });
    };

    return (
      <div style={{ padding: "10px" }}>
        <div>
          <RangePicker
            allowEmpty={[true, true]}
            format={"DD/MM/YYYY"}
            allowClear={true}
            value={[
              dateCreate(subValue?.date?.start || null, true),
              dateCreate(subValue?.date?.end || null, true),
            ]}
            onChange={range => dateChangeHandler(range, true)}
          />

          <CloseButton onClick={() => setIsOpen({ [name]: false })}>
            Close
          </CloseButton>
        </div>
      </div>
    );
  },
);

TableDateRangePicker.displayName = "TableDateRangePicker";
