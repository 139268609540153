import { NOTIFICATIONS } from "consts/notifications";

export enum NotificationField {
  IsNotificationsEnabled = "isNotificationsEnabled",
}

export const notificationMessage = {
  [NotificationField.IsNotificationsEnabled]:
    NOTIFICATIONS.IS_APP_CONFIG_UPDATED,
};

export type StateValues = {
  [NotificationField.IsNotificationsEnabled]: undefined | boolean;
};

export const initialState: StateValues = {
  [NotificationField.IsNotificationsEnabled]: undefined,
};

export type LoadingStateValues = Record<keyof StateValues, boolean>;

export const initialLoadingState: LoadingStateValues = {
  [NotificationField.IsNotificationsEnabled]: false,
};

export type ConfirmModalType = {
  key: NotificationField;
  value: boolean | number;
};
