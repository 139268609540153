import { Avatar } from "components";
import { TABLE_DATE } from "consts";
import { dateTransform } from "helpers/dataHelpers";
import { CancelIcon, CheckCircleIcon, TextFieldsIcon } from "icons";
import { CallBlackIcon } from "icons/CallBlackIcon";
import { FlexContainer } from "styled/Box";
import { theme } from "theme";
import {
  EFilterDateLabel,
  EFilterDateValue,
  ELoyaltyLevelLabel,
  ELoyaltyLevelValue,
} from "types/common";
import { CustomersFilterNames, CustomersFilterTypes } from "types/customers";
import {
  FilterField,
  FilterFieldDropdownOption,
  FilterOption,
} from "types/tableFilters";

export const columns = [
  {
    title: "Customer name",
    dataIndex: "fullName",
    key: "fullName",
    width: "355px",
    render: (name: string | null) =>
      name ? (
        <FlexContainer $align="center" $gap={8}>
          <Avatar name={name} /> {name}
        </FlexContainer>
      ) : null,
  },
  {
    title: "Phone number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "Coupons used",
    dataIndex: "couponUsageCount",
    key: "couponUsageCount",
    align: "center" as const,
  },

  //TODO: remove comment if BE is ready
  // {
  //   title: "Loyalty level",
  //   dataIndex: "loyaltyLevel",
  //   key: "loyaltyLevel",
  //   width: 120,
  // },
  {
    title: "Referral Link",
    dataIndex: "referralsCount",
    key: "referralsCount",
    align: "center" as const,
  },
  {
    title: "VIP Status",
    dataIndex: "isVip",
    key: "isVip",
    align: "center" as const,
    render: (isVip: boolean) =>
      isVip ? <CheckCircleIcon /> : <CancelIcon fill={theme.color.error} />,
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 180,
    sorter: true,
    render: (date: string) =>
      dateTransform({
        date,
        format: TABLE_DATE,
      }),
  },
  {
    title: "Updated at",
    dataIndex: "updatedAt",
    key: "updatedAt",
    width: 180,
    sorter: true,
    render: (date: string) =>
      dateTransform({
        date,
        format: TABLE_DATE,
      }),
  },
];

export const dateDropdownFields: FilterFieldDropdownOption[] = [
  {
    type: EFilterDateValue.TODAY,
    label: EFilterDateLabel[EFilterDateValue.TODAY],
  },
  {
    type: EFilterDateValue.YESTERDAY,
    label: EFilterDateLabel[EFilterDateValue.YESTERDAY],
  },
  {
    type: EFilterDateValue.IN_THE_LAST_7_DAYS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_LAST_7_DAYS],
  },
  {
    type: EFilterDateValue.IN_THE_LAST_30_DAYS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_LAST_30_DAYS],
  },
  {
    type: EFilterDateValue.IN_THE_LAST_90_DAYS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_LAST_90_DAYS],
  },
  {
    type: EFilterDateValue.IN_THE_12_MONTHS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_12_MONTHS],
  },
  {
    type: EFilterDateValue.CUSTOM,
    label: EFilterDateLabel[EFilterDateValue.CUSTOM],
  },
];

export const loyaltyLevelDropdownFields: FilterFieldDropdownOption[] = [
  {
    type: ELoyaltyLevelValue.BRONZE,
    label: ELoyaltyLevelLabel[ELoyaltyLevelValue.BRONZE],
  },
  {
    type: ELoyaltyLevelValue.SILVER,
    label: ELoyaltyLevelLabel[ELoyaltyLevelValue.SILVER],
  },
  {
    type: ELoyaltyLevelValue.GOLD,
    label: ELoyaltyLevelLabel[ELoyaltyLevelValue.GOLD],
  },
  {
    type: ELoyaltyLevelValue.PLATINUM,
    label: ELoyaltyLevelLabel[ELoyaltyLevelValue.PLATINUM],
  },
];

export const filtersList: FilterOption[] = [
  {
    key: CustomersFilterTypes.firstName,
    icon: <TextFieldsIcon />,
    label: CustomersFilterNames[CustomersFilterTypes.firstName],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: CustomersFilterTypes.lastName,
    icon: <TextFieldsIcon />,
    label: CustomersFilterNames[CustomersFilterTypes.lastName],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: CustomersFilterTypes.phoneNumber,
    icon: <CallBlackIcon />,
    label: CustomersFilterNames[CustomersFilterTypes.phoneNumber],
    field: FilterField.INPUT_SELECT,
  },
  //TODO: remove comment if BE is ready
  // {
  //   key: CustomersFilterTypes.lastOrderDate,
  //   label: CustomersFilterNames[CustomersFilterTypes.lastOrderDate],
  //   field: FilterField.MULTISELECT_SINGLE_DATE,
  //   options: dateDropdownFields,
  // },
  //TODO: remove comment if BE is ready
  // {
  //   key: CustomersFilterTypes.mostOrderValue,
  //   label: CustomersFilterNames[CustomersFilterTypes.mostOrderValue],
  //   field: FilterField.MULTISELECT_SINGLE_DATE,
  //   options: dateDropdownFields,
  // },
  {
    key: CustomersFilterTypes.createdAtAfter,
    label: CustomersFilterNames[CustomersFilterTypes.createdAtAfter],
    field: FilterField.MULTISELECT_SINGLE_DATE,
    options: dateDropdownFields,
  },
  //TODO: remove comment if BE is ready
  // {
  //   key: CustomersFilterTypes.loyaltyLevel,
  //   label: CustomersFilterNames[CustomersFilterTypes.loyaltyLevel],
  //   field: FilterField.SELECT,
  //   options: loyaltyLevelDropdownFields,
  // },
];
