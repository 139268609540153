// Lib
import { FC, useEffect, useState } from "react";
// Api
import {
  useGetCoreSettingsQuery,
  useUpdateDeliveryFeeSettingsMutation,
} from "rtkQuery/query/coreSettingsAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
// Types
import { TableAction } from "types/common";
import { FeeRange } from "types/coreSettings";
// Theme
import { theme } from "theme";
// Constants
import { NOTIFICATIONS, rtkQueryParams } from "consts";
// Helpers
import { getConfirmModalTitle } from "../../helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PencilIcon, PersonIcon, PlusIcon, TrashIcon } from "icons";
// Components
import { Table } from "components";
import { ConfirmDialog } from "components/Modals";
import SettingsItem from "../SettingsItem";
import { Switch } from "../Inputs";
import { DeliveryFeeModal } from "../DeliveryFeeModal";
// Styled
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import { ContentBox, SubContentBox } from "styled/Box";

import {
  columns,
  ConfirmModalType,
  DeliveryFeeField,
  notificationMessage,
} from "./config";

export const MinimumOrderDeliveryFeeSettings: FC = () => {
  const { isMobile } = useViewport();

  const { openNotification } = useNotification();

  const [checked, setChecked] = useState<boolean | undefined>();

  const { data, isFetching, error } = useGetCoreSettingsQuery(
    null,
    rtkQueryParams,
  );

  const [update, { isLoading }] = useUpdateDeliveryFeeSettingsMutation();

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setChecked(data?.deliveryFeeSettings?.isVariableDeliveryFeeRangeAllowed);
    }
  }, [data]);

  const [rulesModal, setRulesModal] = useState<boolean | FeeRange>(false);

  const [rulesDeleteDialog, setRulesDeleteDialog] = useState<false | string>(
    false,
  );

  const [confirmModal, setConfirmModal] = useState<ConfirmModalType | false>(
    false,
  );

  const handleUpdateBooleanUpdate = (key: DeliveryFeeField, value: boolean) => {
    setChecked(value);
    setConfirmModal({ key, value });
  };

  const onCancel = () => {
    if (!confirmModal) return;

    setChecked(prev => !prev);
    setConfirmModal(false);
  };

  const onConfirm = async () => {
    if (!confirmModal) return;

    try {
      await update({
        deliveryFeeSettings: {
          isVariableDeliveryFeeRangeAllowed: confirmModal.value,
          feeRanges: data?.deliveryFeeSettings?.feeRanges,
        },
      }).unwrap();

      openNotification({ message: notificationMessage[confirmModal.key] });

      setConfirmModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const onConfirmDelete = async () => {
    if (typeof rulesDeleteDialog !== "string") {
      return;
    }
    try {
      const feeRanges = data?.deliveryFeeSettings?.feeRanges.filter(
        range => range.id !== rulesDeleteDialog,
      );

      await update({
        deliveryFeeSettings: {
          ...data?.deliveryFeeSettings,
          feeRanges,
        },
      }).unwrap();

      openNotification({
        message: NOTIFICATIONS.PACKAGE_RULE_DELETED,
      });

      setRulesDeleteDialog(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const actions: TableAction[] = [
    {
      title: "",
      Icon: PencilIcon,
      type: "Grey",
      loadingId: "0",
      onClick: row => setRulesModal(row),
    },
    {
      title: "",
      Icon: TrashIcon,
      type: "Grey",
      loadingId: "0",
      onClick: row => setRulesDeleteDialog(row?.id),
    },
  ];

  return (
    <>
      <ContentBox $column $gap={12}>
        <Typography.H2>Minimum order delivery fee</Typography.H2>

        <SettingsItem title="Enable order delivery fee">
          <Switch
            isLoading={isFetching}
            canUpdate={true}
            data={checked}
            onChange={value =>
              handleUpdateBooleanUpdate(
                DeliveryFeeField.DeliveryFeeSettings,
                value,
              )
            }
          />
        </SettingsItem>

        <SubContentBox
          $column
          $padding="0px"
          $margin="0 -12px 0 -12px"
          $width="auto"
        >
          <Table
            shadow={false}
            loading={isFetching}
            columns={columns}
            header={{
              totalCount: data?.deliveryFeeSettings?.feeRanges?.length || 0,
              suffix: !isMobile ? (
                <Button.Base
                  type="primary"
                  icon={<PlusIcon fill={theme.color.white} />}
                  onClick={() => setRulesModal(true)}
                >
                  Add Rule
                </Button.Base>
              ) : (
                <Button.SquaredIcon
                  type="primary"
                  icon={<PlusIcon fill={theme.color.white} />}
                  onClick={() => setRulesModal(true)}
                />
              ),
            }}
            actions={actions}
            dataSource={data?.deliveryFeeSettings?.feeRanges || []}
          />
        </SubContentBox>
      </ContentBox>

      <DeliveryFeeModal
        modalData={rulesModal}
        rules={data?.deliveryFeeSettings?.feeRanges || []}
        onClose={() => setRulesModal(false)}
      />

      <ConfirmDialog
        isLoading={confirmModal && isLoading[confirmModal.key]}
        open={!!confirmModal}
        Icon={PersonIcon}
        message={
          confirmModal &&
          getConfirmModalTitle({
            key: confirmModal.key,
            value: confirmModal.value,
          })
        }
        description="Are you sure to continue this action?"
        onCancel={onCancel}
        firstCTAButton={{
          title: "Accept",
          status: "danger",
          disabled: false,
          loading: confirmModal && isLoading[confirmModal.key],
          onClick: onConfirm,
        }}
      />

      <ConfirmDialog
        open={!!rulesDeleteDialog}
        isLoading={isLoading}
        Icon={TrashIcon}
        message="Rule will be deleted"
        description="Are you sure you want to delete this rule?"
        onCancel={() => setRulesDeleteDialog(false)}
        firstCTAButton={{
          title: "Delete Rule",
          status: "danger",
          loading: isLoading,
          onClick: () => onConfirmDelete(),
        }}
      />
    </>
  );
};
