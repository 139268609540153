export type SetCoreSettingsRequestDto = {
  instantOrderCancellationTime?: number;
  cancelTimeAfterStart?: number;
  defaultPreparationTime?: number;
  defaultDeliveryTime?: number;
  deliveryFeeSettings?: DeliveryFeeSettings;
};

export type SetDeliveryFeeSettingsRequestDto = {
  deliveryFeeSettings: DeliveryFeeSettings;
};

export interface CoreSettingsResponseDto {
  id: string;
  createdAt: string;
  updatedAt: string;
  instantOrderCancellationTime: number;
  defaultPreparationTime: number;
  defaultDeliveryTime: number;
  cancelTimeAfterStart: number;
  deliveryFeeSettings: DeliveryFeeSettings;
}

export enum WeekStartDayValue {
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
  Sunday = "sunday",
}

export type RewardsSettingsResponseDto = {
  weekStartDay: WeekStartDayValue;
  isRewardsEnabled: boolean;
  bonusCoins: number;
  metricsMinRequired: number;
  stepMetricSettings: {
    requiredStepsDays: number;
    requiredStepsPerDay: number;
  };
  exerciseMetricSettings: {
    minutesCount: number;
  };
  diaryMetricSettings: {
    daysCount: number;
  };
};

export interface DeliveryFeeSettings {
  isVariableDeliveryFeeRangeAllowed: boolean;
  feeRanges: FeeRange[];
}

export interface FeeRange {
  id?: string;
  minOrderValue: number;
  maxOrderValue: number;
  deliveryFee: number;
}
