export const endpoints = {
  refreshToken: "auth/refresh-token",
  signIn: "auth/firebase",
  me: "auth/me",
  orders: "orders",
  customer_profiles: "customer-profiles",
  customers: "customers",
  transactions: "transactions",
  extendBalanceExpirationDate: "transactions/extend-expiration-date",
  exchangeRate: "exchange-rate",
  balanceExpiration: "balance-expiration",
  settings: "settings",
  authSettings: "settings",
  coreSettings: "settings",
  notificationsSettings: "settings/notifications",
  permissions: "permissions",
  permissionsLimit: "permissions/limit",
  packages: "packages",
  packageTrial: "packages/trial",
  packageRules: "package-rules",
  roles: "roles",
  users: "users",
  waitingList: "waiting-list",
  locations: "locations",
  recommended: "recommended",
  timeSlots: "time-slots",
  cashPayments: "cash-payment/settings",
  referrals: "referrals",
  coupons: "coupons",
  scheduleOrders: "schedule-orders",
  deliveriesManual: "deliveries/manual",
  reviews: "reviews",
  rewards: "rewards/settings",
};
