// Lib
import { FC, memo } from "react";
// Types
import { CustomerData } from "../../types";
// Constants
import { PHONE_CODES } from "consts";
// Components
import { Input, PhoneInput } from "components/Form";
// Styled
import { ContentBox, FlexContainer, InputsGridContainer } from "styled/Box";
import { Typography } from "styled/Typography";

interface InformationsProps {
  orderIdError: string;
  customerData: CustomerData;
  setCustomerData: React.Dispatch<React.SetStateAction<CustomerData>>;
}

export const Informations: FC<InformationsProps> = memo(
  ({ orderIdError, customerData, setCustomerData }) => {
    const { orderId, hashTag, phone, phoneCode, firstName } = customerData;

    const handleInputChange = (key: keyof CustomerData, value: string) => {
      setCustomerData(prev => ({ ...prev, [key]: value }));
    };
    return (
      <ContentBox $column $gap={16}>
        <Typography.H2>Informations</Typography.H2>

        <FlexContainer $column $gap={8}>
          <InputsGridContainer>
            <Input
              label="Order ID"
              placeholder="Order ID"
              value={orderId}
              onChange={e => handleInputChange("orderId", e.target.value)}
              fieldError={orderIdError}
            />

            <Input
              label="Hashtag number"
              placeholder="Hashtag number"
              value={hashTag}
              onChange={e => handleInputChange("hashTag", e.target.value)}
            />

            <Input
              required
              label="Customer name"
              placeholder="Customer name"
              value={firstName}
              onChange={e => handleInputChange("firstName", e.target.value)}
            />

            <PhoneInput
              required
              label="Phone"
              phonePlaceholder="Enter phone number"
              phone={phone}
              phoneCode={phoneCode}
              phoneCodes={PHONE_CODES}
              setPhone={phone => handleInputChange("phone", phone)}
              setPhoneCode={phoneCode =>
                handleInputChange("phoneCode", phoneCode)
              }
            />
          </InputsGridContainer>
        </FlexContainer>
      </ContentBox>
    );
  },
);

Informations.displayName = "Informations";
