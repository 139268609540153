// Lib
import { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
// Api
import { useUpdateDeliveryFeeSettingsMutation } from "rtkQuery/query/coreSettingsAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
// Types
import { FeeRange } from "types/coreSettings";
// Constants
import { NOTIFICATIONS } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
import { validateRage } from "./utils";
// Components
import { Modal } from "components";
import { InputNumber } from "components/Form";
// Styled
import { FlexContainer, InputsGridContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { ErrorMessage } from "components/Form/styled";

import { resolver } from "./validation";

export type RulesForm = {
  minOrderValue: number | null;
  maxOrderValue: number | null;
  deliveryFee: number | null;
};

interface DeliveryFeeModalProps {
  modalData: boolean | FeeRange;
  rules: FeeRange[];
  onClose: () => void;
}

export const DeliveryFeeModal: FC<DeliveryFeeModalProps> = ({
  modalData,
  rules,
  onClose,
}) => {
  const { openNotification } = useNotification();

  const { isMobile } = useViewport();

  const isEdit = !!modalData && typeof modalData !== "boolean";

  const [update, { isLoading }] = useUpdateDeliveryFeeSettingsMutation();

  const { handleSubmit, control, reset, watch } = useForm<RulesForm>({
    mode: "onChange",
    defaultValues: {
      minOrderValue: null,
      maxOrderValue: null,
      deliveryFee: null,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver,
  });

  const [rageError, setRageError] = useState<boolean>(false);

  const minOrderValue = watch("minOrderValue");
  const maxOrderValue = watch("maxOrderValue");
  const deliveryFee = watch("deliveryFee");

  useEffect(() => {
    if (!modalData) {
      reset({ minOrderValue: null, maxOrderValue: null, deliveryFee: null });
      setRageError(false);
    }

    if (isEdit) {
      reset({
        minOrderValue: modalData.minOrderValue,
        maxOrderValue: modalData.maxOrderValue,
        deliveryFee: modalData.deliveryFee,
      });
    }
  }, [modalData]);

  useEffect(() => {
    setRageError(false);
  }, [minOrderValue, maxOrderValue]);

  const onSubmit: SubmitHandler<RulesForm> = async data => {
    const rulesData = isEdit
      ? rules.filter(row => row.id !== modalData.id)
      : rules;

    const isValidRage = validateRage(data, rulesData);

    if (!isValidRage) {
      setRageError(true);
      return;
    }

    const feeRanges = rulesData.concat(data);

    try {
      if (isEdit) {
        console.log("edit");

        update({
          deliveryFeeSettings: {
            isVariableDeliveryFeeRangeAllowed: true,
            feeRanges,
          },
        }).unwrap();

        openNotification({ message: NOTIFICATIONS.PACKAGE_RULE_UPDATED });

        onClose();
      } else {
        update({
          deliveryFeeSettings: {
            isVariableDeliveryFeeRangeAllowed: true,
            feeRanges,
          },
        }).unwrap();

        openNotification({ message: NOTIFICATIONS.PACKAGE_RULE_CREATED });

        onClose();
      }
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const isDisabled =
    !minOrderValue?.toString()?.length ||
    !maxOrderValue?.toString()?.length ||
    !deliveryFee?.toString()?.length;

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  return (
    <Modal
      title={`${isEdit ? "Edit" : "Add"} Rule`}
      width={560}
      open={!!modalData}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer $column $gap={16}>
          <div>
            <InputsGridContainer>
              <Controller
                name="minOrderValue"
                control={control}
                render={({ field, fieldState }) => (
                  <InputNumber
                    label="Order Value From (KD)"
                    required
                    min={0}
                    step={0.001}
                    precision={3}
                    {...field}
                    disabled={isLoading}
                    fieldState={fieldState}
                    fieldError={rageError}
                  />
                )}
              />

              <Controller
                name="maxOrderValue"
                control={control}
                render={({ field, fieldState }) => (
                  <InputNumber
                    label="Order Value To (KD)"
                    required
                    min={0}
                    step={0.001}
                    precision={3}
                    {...field}
                    disabled={isLoading}
                    fieldState={fieldState}
                    fieldError={rageError}
                  />
                )}
              />
            </InputsGridContainer>

            {rageError && (
              <ErrorMessage>
                This range has been added to the list of rules
              </ErrorMessage>
            )}
          </div>

          <Controller
            name="deliveryFee"
            control={control}
            render={({ field, fieldState }) => (
              <InputNumber
                label="Delivery Fee (KD)"
                required
                min={0}
                step={0.001}
                precision={3}
                {...field}
                disabled={isLoading}
                fieldState={fieldState}
              />
            )}
          />
        </FlexContainer>

        <FlexContainer
          $fullwidth
          $align="center"
          $justify="flex-end"
          $gap={8}
          $margin="24px 0 0"
        >
          <FlexContainer
            $align="center"
            $justify="center"
            $gap={8}
            $fullwidth={isMobile}
            $column={isMobile}
          >
            <Button.Base
              $fullWidth={isMobile}
              disabled={isLoading}
              onClick={handleClose}
            >
              Cancel
            </Button.Base>

            <Button.Base
              type="primary"
              htmlType="submit"
              $fullWidth={isMobile}
              loading={isLoading}
              disabled={isDisabled}
            >
              Save
            </Button.Base>
          </FlexContainer>
        </FlexContainer>
      </form>
    </Modal>
  );
};
