import { NOTIFICATIONS } from "consts/notifications";
import { fixedDigitsValue } from "helpers/dataHelpers";

export enum DeliveryFeeField {
  DeliveryFeeSettings = "deliveryFeeSettings",
}

export type ConfirmModalType = {
  key: DeliveryFeeField;
  value: boolean;
};

export const notificationMessage = {
  [DeliveryFeeField.DeliveryFeeSettings]: NOTIFICATIONS.IS_APP_CONFIG_UPDATED,
};

export const columns = [
  {
    title: "Order Value from",
    dataIndex: "minOrderValue",
    key: "minOrderValue",
    render: (value: number) => `${fixedDigitsValue(value, 3)} KD`,
  },
  {
    title: "Order Value to",
    dataIndex: "maxOrderValue",
    key: "maxOrderValue",
    align: "right" as const,
    render: (value: number) => `${fixedDigitsValue(value, 3)} KD`,
  },
  {
    title: "Delivery Fee",
    dataIndex: "deliveryFee",
    key: "deliveryFee",
    align: "right" as const,
    render: (value: number) => `${fixedDigitsValue(value, 3)} KD`,
  },
];
